import logo from "./logo.svg";
import "./App.css";
import IframeComponent from "./components/Iframe";

function App() {
  return (
    <>
      <section className="bg-black dark:bg-white-900">
        <div className="mx-auto grid max-w-screen-xl px-2  text-center  pt-12">
          <div className="mx-auto place-self-center">
            <svg
              version="1.1"
              x="0px"
              y="0px"
              width="265.25577005422"
              height="264.5325780995"
            >
              <g transform="translate(0 0)">
                <g transform="translate(0 0) scale(4.215896454783 4.215896454783) rotate(0 31.458999633789 31.373229980469)">
                  <path
                    d="M50.875 6.995a42.139 42.139 0 0 0-4.748-3.292l-.643.685a43.262 43.262 0 0 1 4.906 3.44c1.928 1.554 4.043 3.533 5.932 5.904.275.342.543.694.805 1.054l.623-.694c-.201-.28-.406-.554-.615-.823-1.975-2.553-4.231-4.655-6.26-6.274zm-.932 1.606a42.433 42.433 0 0 0-5.074-3.551l-6.518 6.964 2.313 2.078a5.948 5.948 0 0 1 1.914-.268l-4.658 4.292-16.451 15.156 11.08 5.051-6.939 7.468L13.77 58.53l-3.215 3.459 4.232-2.87 17.516-11.88 13.805-9.363.031-.021-.018-.009-12.006-5.417 10.078-9.45 4.16-3.9a6.01 6.01 0 0 1-.074 1.868l1.826 1.644 6.418-7.132c-.318-.44-.645-.866-.98-1.282-1.785-2.227-3.773-4.093-5.6-5.576zm.182 4.785c-.41.46-1.008.65-1.576.562a1.765 1.765 0 1 1 1.576-.562zm-2.342-11.45-1.037 1.106a42.32 42.32 0 0 1 4.057 2.778c2.369 1.822 5.131 4.326 7.447 7.461.037.046.07.092.105.139l.027-.031.953-1.058c.001.001-2.105-6.674-11.552-10.395z"
                    fill="#b0bcc2"
                  ></path>
                  <path
                    d="M62.918 33.655c0 .774-.031 1.542-.092 2.301a28.817 28.817 0 0 1-5.551 14.912 28.934 28.934 0 0 1-6.271 6.266 28.998 28.998 0 0 1-6.582 3.625 28.904 28.904 0 0 1-8.467 1.909 29.273 29.273 0 0 1-7.569-.429 28.847 28.847 0 0 1-8.345-2.957l1.17-.869 4.166-3.083 12.385-9.175 6.141-4.549 3.146-2.33-15.654 15.156c1.313.232 2.656.357 4.031.357 4.83 0 9.316-1.477 13.029-4.008a22.635 22.635 0 0 0 3.133-2.559 23.233 23.233 0 0 0 4.793-6.697 22.87 22.87 0 0 0 1.801-5.512 23.05 23.05 0 0 0 .42-4.4c0-1.128-.08-2.235-.238-3.32a22.91 22.91 0 0 0-1.729-6.03 24.072 24.072 0 0 0-.73-1.508l2.201-2.448.258-.286c.498.779.959 1.581 1.383 2.407a29.047 29.047 0 0 1 2.686 7.89c.319 1.73.485 3.512.485 5.337zM35.955 4.643V1.014C35.955.471 35.484 0 34.941 0H29.4c-.543 0-1.014.471-1.014 1.014v4.057a28.507 28.507 0 0 0-6.352 1.983L19.87 3.243c-.271-.472-.879-.608-1.35-.339l-4.801 2.771c-.473.271-.609.879-.338 1.354l2.23 3.851c.012.022.023.04.033.062a28.846 28.846 0 0 0-4.602 4.619c-.012-.007-.02-.009-.029-.016l-3.918-2.232c-.475-.268-1.084-.136-1.354.339l-2.77 4.797c-.271.476-.135 1.083.336 1.354l3.855 2.189a28.357 28.357 0 0 0-1.945 6.327H1.014A1.039 1.039 0 0 0 0 29.333v5.541a1.04 1.04 0 0 0 1.014 1.014h3.799a28.8 28.8 0 0 0 1.348 6.789l-2.916 1.658c-.473.271-.609.879-.338 1.354l2.773 4.797c.268.472.875.608 1.35.34l2.37-1.351a29.278 29.278 0 0 0 4.01 4.908l2.563-2.535 7.426-7.347 5.281-5.226L22 43.388l-4.568 2.815a23.06 23.06 0 0 1-5.182-14.59c0-12.801 10.377-23.176 23.176-23.176.662 0 1.318.034 1.967.09l.203-.219 2.809-3a28.384 28.384 0 0 0-4.45-.665z"
                    fill="#ed1c24"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <h1>
            <div className="flex mx-auto justify-center items-center place-self-center mt-6">
              <p className="font-PinkBlueFont text-primary-50 mr-2 mb-4  dark:text-white text-3xl md:text-5xl sm:text-xl  lg:text-5xl">
                {" "}
                Duckson{" "}
              </p>
              <p className="font-ethnocentricrgFont text-primary-1000 mb-4 max-w-2xl text-xl font-extrabold dark:text-white md:text-5xl sm:text-xl  lg:text-4xl">
                {" "}
                performances{" "}
              </p>
            </div>
            <p className="font-ethnocentricrgFont text-primary-1000  mb-4  text-xl font-extrabold  tracking-tight dark:text-white ">
              {" "}
              Optimisation moteur, conversion flexfuel E85{" "}
            </p>
            <p className="font-ethnocentricrgFont text-primary-1000   text-xl font-extrabold  tracking-tight dark:text-white ">
              {" "}
              diagnostic et mecanique a domicile{" "}
            </p>
          </h1>
          {/*/}
              <h1 className="font-PinkBlueFont text-primary-50 mb-4 max-w-2xl text-4xl font-extrabold leading-none tracking-tight dark:text-white md:text-5xl xl:text-6xl">
              Optimisez les performances de votre véhicule
              </h1>
              <p className=" font-ProtosFont text-gray-500 dark:text-gray-400 md:text-lg lg:mb-8 lg:text-xl">
              Reprogrammation moteur, conversion bioethanol et réparation système anti-pollution
              </p>
            {/* } <Button href="https://github.com/Blazity/next-enterprise" className="mr-3">
                Get started
              </Button>
              <Button
                href="https://vercel.com/new/git/external?repository-url=https://github.com/Blazity/next-enterprise"
                  intent="secondary"
              >
                Deploy Now
    </Button>*/}
        </div>
      </section>
      <section className="bg-black dark:bg-black">
        <div className="mx-auto grid max-w-screen-xl px-4 mt-8 mb-2 text-center">
          <p className="font-ethnocentricrgFont text-white dark:text-white md:text-lg mb-5">
            Donnez une nouvelle vie à votre véhicule en lui offrant une
            optimisation complète ! Je propose des services de reprogrammation
            moteur stage 1, conversion bioéthanol et réparation de système
            anti-pollution pour tous types de véhicules.
          </p>
          <p className="font-ethnocentricrgFont text-white dark:text-white md:text-lg">
            Reprogrammation moteur stage 1 : Améliorez les performances et la
            consommation de carburant de votre véhicule grâce à une
            reprogrammation sur mesure. Conversion bioéthanol : Roulez plus vert
            et plus économique en convertissant votre véhicule au bioéthanol.
            Réparation système anti-pollution : Gardez votre véhicule conforme
            aux normes environnementales et optimisez ses performances en
            faisant réparer son système anti-pollution.
          </p>
        </div>
      </section>

      <section>
        <div className="text-primary-50 mx-auto max-w-screen-xl px-4 mt-4 text-center mb-4">
          <div className="justify-center text-center">
            <div className="flex flex-col items-center justify-center text-center">
              <div className="mb-4 flex h-10 w-10 items-center justify-center rounded-full bg-primary-100 p-1.5 text-orange-700 dark:bg-primary-900 lg:h-12 lg:w-12">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-8 w-8 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              </div>
              <h3 className="font-ethnocentricrgFont mb-2 text-xl font-bold dark:text-white">
                Qui suis-je ?
              </h3>
              <p className="font-ethnocentricrgFont text-white dark:text-gray-400 mb-4">
                Je suis un professionnel expérimenté et passionné par
                l&apos;automobile. Basé en Normandie dans le calvados près de
                Caen. Je suis à votre disposition pour vous conseiller et vous
                proposer la solution la plus adaptée à vos besoins.
              </p>
              <h3 className="font-ethnocentricrgFont mb-2 text-xl font-bold dark:text-white">
                Me contacter
              </h3>
              <p className="font-ethnocentricrgFont text-white mb-2 dark:text-gray-400">
                {" "}
                <a href="mailto:duckson014@gmail.com">
                  {" "}
                  Mail :{" "}
                  <span className="hover:bg-primary-200 hover:px-1">
                    duckson014@gmail.com
                  </span>
                </a>
              </p>
              <p className="font-ethnocentricrgFont text-white dark:text-gray-400">
                {" "}
                <a href="tel:0767094864">
                  Tél :{" "}
                  <span className="hover:bg-primary-200 hover:px-1">
                    06 21 62 17 96
                  </span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="text-primary-50 mx-auto max-w-screen-xl px-4 pt-4 text-center mb-4">
        <div className="flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-8 w-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z"
            />
          </svg>
        </div>
        <div className="font-ethnocentricrgFont text-2xl">
          Simulateur de Puissance
        </div>
      </div>
      <section className="iframe-container mx-auto max-w-screen-xl px-4 pt-12 text-center mb-4 pb-[80%] md:pb-[10%]">
        <IframeComponent></IframeComponent>
      </section>

      <section className="text-primary-50 mx-auto max-w-screen-xl px-4 pt-8 text-center mb-4">
        <div>© 2024 Tous droits réservés</div>
      </section>
    </>
  );
}

export default App;
