import React, { useEffect, useRef, useState } from "react";

const IframeComponent = () => {
  const iframeRef = useRef(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    const iframe = iframeRef.current;

    const handleIframeLoad = () => {
      const element = document.querySelector(".iframe-container");

      if (isFirstLoad) {
        setIsFirstLoad(false);
      } else {
        if (element) {
          element.style.paddingBottom = "100%";
        }
      }
    };

    if (iframe) {
      iframe.addEventListener("load", handleIframeLoad);
    }

    // Nettoyage de l'écouteur d'événements
    return () => {
      if (iframe) {
        iframe.removeEventListener("load", handleIframeLoad);
      }
    };
  }, [isFirstLoad]);

  return (
    <iframe
      ref={iframeRef}
      title="duckson-performance"
      src="https://tuning-shop.com/iframe/iframe.php?user=101&car="
      className="responsive-iframe"
    ></iframe>
  );
};

export default IframeComponent;
